import React from "react";
import Layout from "../components/layout";

const Thanks = ({ pageTitle, location }) => {
  const { state } = location;
  const name = state ? state.name : "";
  return (
    <Layout pageTitle={pageTitle}>
      <div>
        <h3 style={{ marginTop: "20px" }}>
          Obrigado {name}, recebemos sua mensagem e entraremos em contato em
          breve.
        </h3>
      </div>
    </Layout>
  );
};

Thanks.defaultProps = {
  pageTitle: "Obrigado",
};
export default Thanks;
